<template>
    <div class="services-social">
        <div class="services-social__section">
            <v-card-text>
                Das Sozialrecht umfasst ein großes Spektrum. Häufig treten hierbei Fragen aus folgenden Bereichen auf:
            </v-card-text>
        </div>
        <div class="services-social__section">
            <v-card-subtitle class="text-wrap text-left">
                Sie haben einen Rentenantrag gestellt oder wollen einen solchen stellen?
            </v-card-subtitle>
            <v-card-text>
                <p>
                    Während sich bei der Altersrente in der Regel kaum Probleme ergeben, stellt sich häufig bei Menschen die Frage, ob sie noch in der Lage sind, überhaupt noch oder noch in einem ausreichenden Umfang am Berufsleben teilnehmen oder ob Sie eine Rente wegen verminderter Erwerbsfähigkeit erhalten können. Früher sprach man insoweit von einer Erwerbsunfähigkeitsrente oder abgekürzt von einer EU-Rente.
                </p>
            </v-card-text>
        </div>
        <div class="services-social__section">
            <v-card-subtitle class="text-wrap text-left">
                Sie begehren die Feststellung eines Grades der Behinderung (GdB)?
            </v-card-subtitle>
            <v-card-text>
                <p>
                    Die Feststellung des Grades der Behinderung ist nicht immer geboten. In manchen Fällen bringt die zu erwartenden Feststellung einem Mandanten gar nichts. In anderen Fällen kann es sinnvoll sein, einen bestimmten Grad der Behinderung festgestellt zu bekommen.
                </p>
                <p>
                    Ob und in welcher Höhe ein GdB festgestellt wird, das bestimmt nicht irgendein Arzt “Pi mal Daumen”, sondern die sog. Anlage zu § 2 der Versorgungsmedizin-Verordnung, kurz: Versorgungsmedizinische Grundsätze.
                </p>
                <p>
                    So wirkt sich ein Grad der Behinderung von mindestens 50 auch und gerade im Arbeitsleben positiv aus, jedenfalls dann, wenn der Schwerbehinderte einen Arbeitsplatz hat. Der Betreffende genießt dann nämlich den besonderen Kündigungsschutz schwerbehinderter Menschen. In einem solchen Fall muß der Arbeitgeber vor Ausspruch der Kündigung nach § 85 SGB IX die Zustimmung des Integrationsamtes einholen. Eine vom Arbeitgeber ohne diese Zustimmung ausgesprochene Kündigung ist unwirksam. Das Integrationsamt prüft, ob eine leidensgerechte Ausgestaltung des Arbeitsplatzes möglich ist und stellt regelmäßig auch Geldbeträge zur Verfügung, wenn dadurch ein Arbeitsplatz behinderten- oder leidensgerecht ausgestaltet werden kann.
                </p>
            </v-card-text>
        </div>
        <div class="services-social__section">
            <v-card-subtitle class="text-wrap text-left">
                Ist es sinnvoll einen Gleichstellungsantrag zu stellen?
            </v-card-subtitle>
            <v-card-text>
                <p>
                    Personen mit einem Grad der Behinderung von weniger als 50, aber mindestens 30, können auf Antrag von der Agentur für Arbeit schwerbehinderten Menschen gleichgestellt werden, wenn sie infolge ihrer Behinderung ohne die Gleichstellung einen geeigneten Arbeitsplatz nicht erlangen oder behalten können. Erfolgt die Gleichstellung, dann genießen die Betreffenden auch den besonderen Kündigungsschutz Schwerbehinderter.
                </p>
                <p>
                    Im August 2014 wurde mir durch die Rechtsanwaltskammer Hamm die Berechtigung zuerkannt, die Bezeichnung “Fachanwalt für Sozialrecht” zu führen.
                </p>
            </v-card-text>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ServicesSocial',
}
</script>