<template>
    <div class="services-condominium">
        <div class="services-condominium__section">
            <v-card-subtitle class="text-wrap text-left">
                Was ist Wohnungseigentum?
            </v-card-subtitle>
            <v-card-text>
                <p>
                    In Deutschland gilt der Grundsatz, dass der Eigentümer eines Grundstücks gleichzeitig auch der Eigentümer all dessen ist, was fest mit dem Grund und Boden verbunden ist. Dies war über Jahrhunderte mehr oder weniger unproblematisch. Ein Grundstück, ein Haus, ein Eigentümer.
                </p>
                <p>
                    Nach dem zweiten Weltkrieg war Deutschland gerade in den Ballungsräumen großflächig zerstört, so dass die Idee entstand, dass mehrere Personen ein Haus bauen können, in dem jeder Eigentümer einer bestimmten Wohnung sein sollte. Dies ist kurz gesagt der Hintergrund für die Entstehung des Wohnungseigentumsgesetzes (WEG). Dieses ermöglicht den Bau mehrerer Häuser auf einem Grundstück, wobei dieses dann nicht allen gemeinschaftlich gehört, sondern Einzelnen ganz bestimmte Wohnungen gehören.
                </p>
            </v-card-text>
        </div>
        <div class="services-condominium__section">
            <v-card-subtitle class="text-wrap text-left">
                Gemeinschaftseigentum oder Sondereigentum?
            </v-card-subtitle>
            <v-card-text>
                <p>
                    In Wohnungseigentumsanlagen ist aber nicht immer eine konkrete Eigentumszuordnung zu einer bestimmten Wohnung möglich. So ist z.B. ein Treppenhaus nicht einer einzelnen Wohnung zugeordnet. Auch gehört das Dach oder die Außenfassade nicht einer bestimmten Partei, ebenso wenig gehört einem Einzelnen die Heizungsanlage des gesamten Objekts. Im Wohnungseigentumsgesetz wird daher unterschieden zwischen Gemeinschaftseigentum, also dem allen gemeinsam Gehörenden, und Sondereigentum, das nur dem einzelnen Eigentümer gehört.
                </p>
            </v-card-text>
        </div>
        <div class="services-condominium__section">
            <v-card-subtitle class="text-wrap text-left">
                Der Hausverwalter
            </v-card-subtitle>
            <v-card-text>
                <p>
                    Das WEG schreibt vor, dass für jede Eigentumsanlage ein Hausverwalter zu bestellen ist, der auch die Aufgabe hat, die widerstreitenden Interessen der Eigentümer im Interesse des gesamten Objekts zu koordinieren und darauf zu achten, dass das, was allen gehört, in einem ordnungsgemäßen Zustand bleibt.
                </p>
                <p>
                    Im Rahmen meiner langjährigen Tätigkeit als Hausverwalter einer Wohnungseigentumsanlage mit 15 Parteien in Münster-Roxel habe ich einen umfassenden Einblick in die Belange der Eigentümergemeinschaft und die damit verbundenen Problemfelder gewonnen.
                </p>
                <p>
                    Im April 2011 wurde mir durch die Rechtsanwaltskammer Hamm die Berechtigung zuerkannt, die Bezeichnung “Fachanwalt für Miet- und WEG-Recht” zu führen.
                </p>
            </v-card-text>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ServicesCondominium',
}
</script>